<template>
    <div>
      <v-file-input
          v-model="image"
          :label="label"
          accept="image/png, image/jpeg, image/webp"
          append-icon="mdi-close"
          @click:append="clearImage()"
      > </v-file-input>
      <img v-if="file" :src="imageUrl">
      <v-alert v-if="message" border="left" color="blue-grey" dark v-html="message"/>
    </div>
</template>

<script>

import APIService from "@/services/APIService";

export default {
  name: "ImageInput",
  props: {
    value: {
      type: Object,
    },
    label: {
      type: String,
      required: false,
    }
  },
  data() {
    return {
      image: undefined,
      file: undefined,
      message: ''
    }
  },
  methods: {
    clearImage() {
      this.file = undefined
      this.$emit('input', undefined)
    }
  },
  computed: {
    imageUrl() {
      return `${process.env.VUE_APP_ROOT_API}/content/${this.file.path}`
    }
  },
  watch: {
    image: function() {
      console.log("upload file", this.image)
      if(this.image) {
        APIService.upload(this.image).then((response) => response.data).then(fileMeta => {
          this.file = { id: fileMeta.id, path: fileMeta.path }
          this.$emit('input', this.file)
          this.image = undefined
        }).catch((error) => {
          this.message = `Das Bild konnte nicht übertragen werden:<br> ${error.response?.data?.message}`;
        })
      }
    },
    value: function() {
      console.log("Value changed")
      this.message = ''
      this.image = undefined
      this.file = this.value
    },
  },
  mounted() {
    this.file = this.value
  }
}
</script>

<style lang="scss" scoped>
  img {
    max-width: 200px;
  }
</style>
